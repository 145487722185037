import { Color } from "../utils/colors";


export enum EventState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
};

export const EventStateName = new Map<EventState, string>([
  [ EventState.DRAFT, 'admin.events.state.DRAFT' ],
  [ EventState.PUBLISHED, 'admin.events.state.PUBLISHED' ],
  [ EventState.UNPUBLISHED, 'admin.events.state.UNPUBLISHED'],
  [ EventState.CANCELLED, 'admin.events.state.CANCELLED'],
  [ EventState.FINISHED, 'admin.events.state.FINISHED' ],
]);

// toto se asi nikde nepoužívá. Zbytečné?
export const EventStateFutureName = new Map<EventState, string>([
  [ EventState.DRAFT, 'admin.events.state.transition.DRAFT' ],
  [ EventState.PUBLISHED, 'admin.events.state.transition.PUBLISHED' ],
  [ EventState.UNPUBLISHED, 'admin.events.state.transition.UNPUBLISHED'],
  [ EventState.CANCELLED, 'admin.events.state.transition.CANCELLED'],
  [ EventState.FINISHED, 'admin.events.state.transition.FINISHED' ],
]);

export const EventStateColor = new Map<EventState, Color | string>([
  [ EventState.DRAFT, Color.WARN ],
  [ EventState.PUBLISHED, Color.SUCCESS ],
  [ EventState.UNPUBLISHED, '--yellow-300' ],
  [ EventState.CANCELLED, '--gray-400' ],
  [ EventState.FINISHED, '--purple-500' ],
]);
